// Helper function to generate the Basic Auth header
function makeBaseAuth(user, password) {
    const token = btoa(`${user}:${password}`);
    return `Basic ${token}`;
}

export function fundFormFetchData() {
    // const username = 'umd'; // Replace with your actual username
    // const password = 'password'; // Replace with your actual password
    const bearerToken = import.meta.env.VITE_DEFAULT_GQL_TOKEN;

    let dropdown = document.getElementById("js-fund");
    if(dropdown) {
        dropdown.addEventListener('change', function(event) {
            let id = dropdown.value;
            fetch("/api", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': "application/json",
                    // Commented as doesnt work
                    // 'Authorization': `${makeBaseAuth(username, password)}, Bearer ${bearerToken}` // Combined Authorization header
                    'Authorization': `Bearer ${bearerToken}` // Combined Authorization header
                },
                cache: "force-cache",
                body: JSON.stringify({
                    query: `query Funds($section: [String], $id: [QueryArgument]) {
                        entries(section: $section, id: $id) {
                            id
                            ... on fundsAll_Entry {
                                description
                                keywords
                            }
                            ... on fundsAthletic_Entry {
                                description
                                keywords
                            }
                        }
                    }`,
                    variables: {
                        section: "funds",
                        id: id
                    }
                })
            })
            .then(response => response.json())
            .then(response => response.data.entries[0])
            .then((response) => {
                document.getElementById("js-fund-desc").value = response.description;
                document.getElementById("js-fund-keywords").value = response.keywords;
            });
        });
    }
}